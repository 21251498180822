.admin_header {
  padding-left: 40px;
  padding-right: 40px;
  margin: 0;
  display: flex;
  align-items: center;
  line-height: 40px;

  > .anticon {
    color: #fff;
    font-size: 30px;
    outline: none;
    margin-right: 20px;

    @include media('<=960px') {
      font-size: 23px;
    }
  }

  .ant-dropdown-link {
    font-size: 16px;
    color: #fff;
    margin-left: 2rem;
    cursor: pointer;
    //margin-right: 30px;

    &:hover {
      text-decoration: none;
    }

    @include media('<=960px') {
      font-size: 12px;
    }

    i {
      vertical-align: baseline;
      margin-left: 6px;
    }
  }

  .ant-select-arrow {
    top: 38%;
  }

  .ant-select-single {
    &:not(.ant-select-customize-input) {
      .ant-select-selector {
        border: none;
      }
    }
  }
}

.ant-dropdown-trigger > .anticon.anticon-down,
.ant-dropdown-link > .anticon.anticon-down,
.ant-dropdown-button > .anticon.anticon-down {
  font-size: 10px;
  vertical-align: baseline;
  margin-right: 100px;
  margin-left: 5px;
}

.admin_header {
  line-height: 60px;
}

.ant-layout-header span {
  margin-top: 0 !important;
}

.ant-select-item-option-selected {
  &:not(.ant-select-item-option-disabled) {
    background-color: #ffffff;
    font-weight: 400;

    &:hover {
      background-color: #f5f5f5;
    }
  }
}

@media only screen and (max-width: 380px) {
  .admin_header {
    .ant-dropdown-link {
      margin-right: 10px;
    }
  }
}

.logo-mobile {
  display: none;

  @include media('<=lg') {
    display: none;
    width: 100px;
    right: -22px;
    position: relative;
  }
}

.btn-collapse {
  display: none;

  @include media('<=lg') {
    display: block;
    z-index: 1000;
  }
}

@media (max-width: 380px) {
  .header {
    position: relative;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 62px;
    padding: 0 10px;
    height: 62px;
    display: flex;
    align-items: center;
    background-color: $primary-color;
  }

  .header-overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.3);
    z-index: 100;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
  }
}
@media (min-width: 380px) {
  .header {
    display: none;
  }
}

.header-overlay.isActive {
  opacity: 1;
  visibility: visible;
}

.desna-strana {
  display: flex;
}

.header .btn-menu {
  position: relative;
  width: 90px !important;
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
}

.sider-profile-controls {
  box-sizing: border-box;
  // width: 100%;
  line-height: 40px;
  color: $primary-color;
  margin: 10px 0;
  display: flex;
  width: fit-content;
  .sider-profile-controls-profile,
  .sider-profile-controls-logout {
    font-size: 22px;
  }

  .sider-profile-controls-profile:hover,
  .sider-profile-controls-logout:hover,
  .sider-profile-controls-language:hover {
    color: darken($primary-color, 5%);
  }

  .sider-profile-controls-profile {
    margin-right: 20px;
  }

  .sider-profile-controls-language {
    display: none;
    padding: 0;
    margin: 0;
    color: $k100;
  }
}

.nav-display {
  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .ant-menu {
      flex-grow: 2;
    }
  }
}

.ant-menu-submenu-selected .ant-menu-submenu-title .ant-menu-title-content {
  font-weight: 700;
}

.ant-drawer-content-wrapper {
  width: 480px !important;
  max-width: 480px !important;
}

.account {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  // margin-bottom: 20px;

  & a {
    width: 44px;
    height: 44px;
    margin: 0 !important;
    display: block;

    & > span {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  & span {
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.gl_header {
  position: sticky;
  justify-content: space-between;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: 164px 1fr 88px;
  width: 100%;
  z-index: 100;
  box-shadow: 0 -6px 10px 5px rgba(0, 0, 0, 0.5);
  & ul {
    justify-content: center;
  }
  & .sider-profile-controls {
    & > div {
      margin: 0 !important;
    }
  }
  & .logo-desktop {
    display: flex;
    align-items: center;

    & .logo {
      & img {
        object-fit: contain;
      }
    }
  }
  .ant-menu-overflow-item {
    align-self: center;
  }

  @media (max-width: 380px) {
    .btn-close-menu {
      position: absolute;
      top: 15px;
      right: 15px;
      border-radius: 50%;

      & > span {
        color: white;
        opacity: 1;
      }
    }

    transform: translateX(-100%);

    &.isActive {
      transform: translateX(0);
    }
  }

  @media (min-width: 380px) {
    .btn-close-menu {
      display: none;
    }
  }
}

.ant-menu-dark.ant-menu-horizontal > .ant-menu-item,
.ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
  padding: 0 10px;
}

.ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical-left > .ant-menu-item,
.ant-menu-vertical-right > .ant-menu-item,
.ant-menu-inline > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: fit-content;
}

.logo {
  z-index: 101;
}

.account {
  z-index: 101;
}

.custom-menu-keys {
  color: #000000;
  .menu-option {
    position: relative;
    .menu-badge {
      position: absolute;
      top: 0;
      right: 30%;
      background: red;
      padding: 5px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: fit-content;
      height: fit-content;
      min-width: 15px;
      min-height: 15px;
    }

    .menu-badge p {
      margin: 0;
      padding: 0;
      color: white;
      font-weight: 600;
      text-align: center;
    }
    img {
      width: 50px;
      height: 50px;
    }
    cursor: default;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5px;
    margin-top: 5px !important;
  }
}
.ant-menu-dark .ant-menu-item:hover,
.ant-menu-dark .ant-menu-item-active,
.ant-menu-dark .ant-menu-submenu-active,
.ant-menu-dark .ant-menu-submenu-open,
.ant-menu-dark .ant-menu-submenu-selected,
.ant-menu-dark .ant-menu-submenu-title:hover {
  color: $primary-color !important;
  background-color: transparent;
}
