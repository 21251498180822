html {
  min-height: 100%;
  height: 100%;
}

body {
  background: $bodyColor;
  min-width: 320px;
  height: 100%;
}

#__next {
  height: 100%;
}

.ant-layout-sider-dark {
  background: $primary-color;

  position: fixed;
  z-index: 2;
  height: 100vh;
  border-bottom-right-radius: 20px;
}

#admin-layout {
  height: 100%;
  overflow: hidden;

  section {
    padding: 20px 0;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }

    &.ant-layout {
      padding: 0;
      overflow: hidden;
    }
  }

  .ant-layout-sider {
    background: $primary-color;
    min-height: 100vh;
    z-index: 150;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .ant-layout-content {
    padding: 15px 15px 0;
    overflow-x: hidden;
    overflow-y: auto;

    > *:last-child {
      margin-bottom: 15px;
    }
  }
}

header.ant-layout-header {
  // display: flex;
  // flex-direction: column;
  // align-items: flex-end;
  height: fit-content;
  .ant-menu {
    line-height: normal;
  }
  @include media('<=lg') {
    // flex-direction: row;
    // justify-content: center;
    // align-items: center;
    z-index: 2 !important;

    > button {
      margin-right: auto;
    }

    .ant-dropdown-link {
      margin-left: auto;
    }
  }
  span {
    margin-top: 10px;
  }
}

.jodit-workplace {
  min-height: unset !important;
  height: 300px !important;
}

.admin_header {
  @include media('<=lg') {
    padding-left: 20px !important;
  }
}

header.ant-layout-header {
  //background: #2c2738;
  background: $k3;
}

/*.ant-menu.ant-menu-dark,*/
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background: lighten($primary-color, 15%);
}

.ant-menu.ant-menu-dark {
  background: transparent;
}

.content-space {
  margin-top: 30px;
  margin-bottom: 30px;
}

@media (max-width: 991.98px) {
  .content-space {
    margin-top: 30px;
  }
}

.ant-tabs {
  padding: 14px;
}

.ant-tabs-left {
  padding-top: 0;
}

@media only screen and (max-width: 992px) {
  .ant-layout-sider {
    width: 100% !important;
    max-width: 100% !important;
    padding: 0 10px;
  }

  .panel-body {
    .panel-body {
      padding: 0;
      .panel-body {
        padding: 0;
      }
    }
  }

  .panel-body {
    .panel-primary {
      .panel-body {
        padding: 1em;
      }
    }
  }
}

.data-view-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.data-view-background {
  background-color: #fbe7c5;
}

.demand-wrapper {
  position: relative;
  border: 1px solid $k15;
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  label {
    position: absolute;
    top: -12px;
    z-index: 1;
    left: 2em;
    background-color: white;
    padding: 0 5px;
  }
}
