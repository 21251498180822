th,
td {
  font-size: 0.875em;
}

th {
  text-align: left;
}
.table-action-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.table {
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border-radius: 2px;

  > thead > tr > th,
  > tbody > tr > th,
  > tfoot > tr > th,
  > thead > tr > td,
  > tbody > tr > td,
  > tfoot > tr > td {
    padding: 13px 15px;
    line-height: 1.42857143;
    vertical-align: top;
    border-top: 1px solid #ffffff;
  }

  > thead > tr > th {
    vertical-align: bottom;
    border-bottom: 2px solid #ffffff;
    text-transform: uppercase;
  }

  > caption + thead > tr:first-child > th,
  > colgroup + thead > tr:first-child > th,
  > thead:first-child > tr:first-child > th,
  > caption + thead > tr:first-child > td,
  > colgroup + thead > tr:first-child > td,
  > thead:first-child > tr:first-child > td {
    border-top: 0;
  }

  > tbody {
    + tbody {
      border-top: 2px solid #ffffff;
    }

    tr {
      &:hover {
        > td {
          background-color: #f0f6f8;
        }
      }
    }
  }
}

.ant-table-thead tr th:first-child {
  text-align: left;
}

.table > thead > tr:first-child {
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
}
.table > thead > tr:first-child > th:first-child {
  border-top-left-radius: 2px;
}
.table > thead > tr:first-child > th:last-child {
  border-top-right-radius: 2px;
}
.table > tfoot > tr:last-child {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}
.table > tfoot > tr:last-child > th:first-child {
  border-bottom-left-radius: 2px;
}
.table > tfoot > tr:last-child > th:last-child {
  border-bottom-right-radius: 2px;
}
.table > tbody > tr:last-child > th:first-child,
.table > tbody > tr:last-child > td:first-child {
  border-bottom-left-radius: 2px;
}
.table > tbody > tr:last-child > th:last-child,
.table > tbody > tr:last-child > td:last-child {
  border-bottom-right-radius: 2px;
}
.table > tfoot + tbody > tr:last-child > th:first-child,
.table > tfoot + tbody > tr:last-child > td:first-child {
  border-bottom-left-radius: 0;
}
.table > tfoot + tbody > tr:last-child > th:last-child,
.table > tfoot + tbody > tr:last-child > td:last-child {
  border-bottom-right-radius: 0;
}

.table-condensed > thead > tr > th,
.table-condensed > tbody > tr > th,
.table-condensed > tfoot > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > tbody > tr > td,
.table-condensed > tfoot > tr > td {
  padding: 5px;
}

.table-bordered {
  border: 1px solid #ffffff;

  > thead > tr > th,
  > tbody > tr > th,
  > tfoot > tr > th,
  > thead > tr > td,
  > tbody > tr > td,
  > tfoot > tr > td {
    border: 1px solid #ffffff;
  }

  > thead > tr > th,
  > thead > tr > td {
    border-bottom-width: 2px;
  }
}

.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #f2f2f2;
}
.table-hover > tbody > tr:hover {
  background-color: #e7e9ee;
}

.table-responsive {
  overflow-x: auto;
  overflow-y: hidden;
  min-height: 0.01%;

  @include media('<md') {
    width: 100%;
    margin-bottom: 12.75px;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #ffffff;
  }

  th,
  td {
    @include media('<md') {
      white-space: nowrap;
    }
  }

  > .table-bordered {
    @include media('<md') {
      border: 0;

      th {
        &:first-child {
          border-left: 0;
        }

        &:last-child {
          border-right: 0;
        }
      }
    }
  }
}

/* colors */
.table > .success > tr > th,
.table > .success > tr > td {
  background-color: #505b72;
  color: #fff;
  border-color: rgba(255, 255, 255, 0.25);
}
.table > .primary > tr > th,
.table > .primary > tr > td {
  background-color: #2574ab;
  color: #fff;
  border-color: rgba(255, 255, 255, 0.25);
}
.table > .success-2 > tr > th,
.table > .success-2 > tr > td {
  background-color: #4f66a0;
  color: #fff;
  border-color: rgba(255, 255, 255, 0.25);
}
.table > .danger > tr > th,
.table > .danger > tr > td {
  background-color: #d9534f;
  color: #fff;
  border-color: rgba(255, 255, 255, 0.25);
}
.table > .success > tr:first-child {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.table > .success > tr:first-child > th:first-child,
.table > .success > tr:first-child > td:first-child {
  border-top-left-radius: 0;
}
.table > .success > tr:first-child > th:last-child,
.table > .success > tr:first-child > td:last-child {
  border-top-right-radius: 0;
}

.table-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 15px;

  a {
    display: inline-flex;
    width: 20px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  i {
    vertical-align: baseline;
  }
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #000000;
}

.ant-tabs-tab:hover {
  color: #4f66a0;
}

.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active {
  color: $secondary-color;
}

.ant-tabs-ink-bar {
  background: $primary-color;
}

.ant-table-row-expand-icon:focus,
.ant-table-row-expand-icon:hover {
  color: $secondary-color;
}

.ant-picker:hover,
.ant-picker-focused {
  border-color: #4f66a0;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
.ant-picker-range .ant-picker-active-bar {
  background: #4f66a0;
}

td.ant-table-column-sort {
  background: initial;
}

.actions-block {
  background-color: $wht !important;
  min-height: 100px;
  border-radius: $table-border-radius-top;
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-bottom: 1px solid #e8e8e8;
}

.actions-block-eop {
  background-color: $wht !important;
  min-height: 100px;
  border-radius: $table-border-radius-top;
  display: flex;
  flex-direction: column;

  justify-content: space-between;

  border-bottom: 1px solid #e8e8e8;
  a {
    width: fit-content;
    margin-bottom: 15px;
    margin-left: 15px;
  }
}
.table {
  border-radius: $table-border-radius;
  box-shadow: $table-box-shadow;
  overflow: hidden !important;
  background-color: $wht;
  min-height: calc(100vh - 122px);
  // height: 420px;
  // @media (min-height: 800px) {
  //   height: 520px;
  // }
  // @media (min-height: 900px) {
  //   height: 800px;
  // }
  @media (min-width: 992px) {
    min-height: calc(100vh - 60px);
  }
}

.ant-table-filter-dropdown {
  padding: 10px;
  border-radius: 4px;

  .ant-btn {
    @include smallbtn;
  }
}

.lock:hover .icon-unlock,
.lock .icon-lock {
  display: none;
}
.lock:hover .icon-lock {
  display: inline;
}
.lock:hover svg {
  color: $primary-color !important;
}

.invoices .ant-table-bordered .ant-table-thead > tr > th {
  padding-top: 35px !important;
  padding-bottom: 15px !important;
}
.newsletters .ant-table-bordered .ant-table-thead > tr > th {
  padding-top: 35px !important;
  padding-bottom: 15px !important;
}

.search {
  min-height: 44px;
  border-radius: 90px;
  padding: 0 20px;
}

.actions-block {
  & .left-side {
    display: flex;
  }
}

.table-orders-okvir {
  min-height: 100%;
  background-color: $wht;
  border-radius: 10px;
}

table thead tr th {
  border-top: 0 !important;
}

.panel-body {
  margin-top: 15px;
}

.ant-table-tbody {
  & tr {
    &:nth-child(2n) {
      background-color: #f2f2f2c7;
    }
    &:first-child {
      & td {
        display: none;
      }
    }
  }
}

.ant-table-pagination.ant-pagination {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  padding-bottom: 15px !important;
  padding-top: 15px !important;
  border-bottom: 1px solid #e8e8e8 !important;

  & li {
    width: 28px !important;
    height: 28px !important;
    line-height: 28px !important;
  }
}
.gray-row {
  background-color: #f5f5f5;
}

.statistic-block {
  padding-top: 20px;
  padding-left: 30px;
  margin-bottom: 20px;
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: 100% !important;
  }
  .ant-form .ant-input,
  .ant-form .ant-input-affix-wrapper {
    border-radius: 0 !important;
  }
  .ant-form-item-label {
    text-align: left;
    min-width: 0px;
  }
  .ant-form-item-row {
    gap: 10px !important;
    align-items: center !important;
  }
  .ant-row {
    align-items: center;
    gap: 30px;
    width: 100%;
  }
  .ant-form-item {
    margin-bottom: 0;
  }
  hr {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 0;
    min-height: 0;
    display: flex;
    align-items: center;
  }
  .statistic-block-total {
    display: flex;
    gap: 10px;
  }
}
.eop-export {
  display: flex;
  align-items: center;
  gap: 20px;
}
.eop-table {
  .ant-table-cell-ellipsis .ant-table-column-title {
    white-space: normal;
    font-size: 11px;
    text-align: center;
  }
  .ant-table-cell-ellipsis {
    white-space: normal;
  }
}
