main.ant-layout-content {
  section.ant-layout.ant-layout-has-sider.site-layout-background {
    height: 100%;
    .ant-layout-sider-light {
      padding: 30px 0 30px 20px;
    }
  }
}

.ant-menu-inline .ant-menu-submenu-title {
  margin-top: 2px;
  margin-bottom: 2px;
}

.ant-layout-sider {
  .logo {
    text-align: center;

    img {
      max-height: 100% !important;
      max-width: 100% !important;
      width: 100%;
    }

    button {
      margin-top: 15px;
    }
  }

  .ant-menu-submenu-title {
    padding-left: 34px !important;
    padding-right: 34px;
    text-align: left;

    .ant-menu-title-content {
      font-size: 16px;
      font-weight: 700;
      color: $wht;
    }

    span {
      i {
        vertical-align: inherit;
        position: relative;
        top: -1px;
      }
    }

    .ant-menu-submenu-arrow {
      transform: translateY(2px);
    }
  }

  .ant-menu-submenu-open {
    .ant-menu-submenu-title {
      .ant-menu-submenu-arrow {
        transform: translateY(0);
      }
    }
  }

  .ant-menu-item {
    margin-top: 0;
    margin-bottom: 0;

    &:first-child {
      margin-top: 0px;
    }

    &:last-child {
      margin-bottom: 0px;
    }

    a,
    a:visited {
      &:hover {
        text-decoration: none;
      }
    }
  }

  .ant-menu-sub.ant-menu-inline > .ant-menu-item {
    height: 35px;
  }

  .ant-menu-inline .ant-menu-item:not(:last-child) {
    margin-bottom: 0;
  }
}

.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-item-only-child {
  margin-bottom: 0;
  margin-top: 0;
}

.ant-layout-sider .ant-menu-item:last-child {
  margin-bottom: 0;
}

span.anticon {
  vertical-align: 0.15em;
}

@media only screen and (max-width: 992px) {
  .ant-layout-sider {
    .logo {
      border: none;
    }
  }

  main.ant-layout-content section.ant-layout.ant-layout-has-sider.site-layout-background {
    display: flex;
    flex-direction: column;
  }

  .ant-layout.ant-layout-has-sider > .ant-layout-content {
    width: 100%;
    padding: 0px !important;
  }
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: transparent;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected:before {
  content: '';
  width: 5px;
  height: 25px;
  background: $wht;
  border-radius: 0 6px 6px 0;
  position: absolute;
  left: 0;
  top: 5px;
}

.ant-menu-inline .ant-menu-submenu,
.ant-menu-vertical-left .ant-menu-submenu,
.ant-menu-vertical-right .ant-menu-submenu,
.ant-menu-vertical .ant-menu-submenu {
  font-weight: 700;
}

.ant-drawer-content {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.ant-layout-sider-children {
  & ul {
    & > li {
      & > .ant-menu-submenu-title {
        // padding-left: 0 !important;
        padding-right: 0 !important;
        margin: 0 !important;

        & .ant-menu-title-content {
          padding: 0;
        }
      }
    }
  }
}

/* SIDEBAR NAVIGATION - LEFT SIDE */
.gl_sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  max-width: 220px !important;
  z-index: 991;
  transition: transform 0.3s ease !important;
  padding: 20px 0 !important;

  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  // LOGO
  & .logo-desktop {
    display: inline-block;
    margin-top: 30px;
    margin-bottom: 30px;
    & .logo {
      & img {
        object-fit: contain;
      }
    }
  }

  // NAVIGATION
  .ant-menu {
    flex-grow: 2;
  }

  // ACCOUNT
  & .sider-profile-controls {
    margin-top: 30px !important;
    margin-bottom: 30px !important;

    & > div {
      margin: 0 !important;
    }
  }

  @media (max-width: 991.98px) {
    .btn-close-menu {
      position: absolute;
      top: 15px;
      right: 15px;
      border-radius: 50%;

      & > span {
        color: white;
        opacity: 1;
      }
    }

    transform: translateX(-100%);

    &.isActive {
      transform: translateX(0);
    }
  }

  @media (min-width: 992px) {
    .btn-close-menu {
      display: none;
    }
  }
}
