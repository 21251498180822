.ant-table {
  font-size: 1em;
}
.data-table-nekretnine {
  th:nth-child(1),
  td:nth-child(1) {
    width: 50px;
  }
  //   th:nth-child(2),
  //   td:nth-child(2) {
  //     width: 80px;
  //   }
  //   th:nth-child(3),
  //   td:nth-child(3) {
  //     width: 120px;
  //   }
  //   th:nth-child(4),
  //   td:nth-child(4) {
  //     width: 115px;
  //   }
  //   th:nth-child(5),
  //   td:nth-child(5) {
  //     width: 110px;
  //   }
  th:nth-child(6),
  td:nth-child(6) {
    width: 70px;
  }
  //   th:nth-child(7),
  //   td:nth-child(7) {
  //     width: 100px;
  //   }
  //   th:nth-child(8),
  //   td:nth-child(8) {
  //     width: 130px;
  //   }
  //   th:nth-child(9),
  //   td:nth-child(9) {
  //     width: 130px;
  //   }
  // th:nth-child(10),
  // td:nth-child(10) {
  //   width: 130px;
  // }
  //   th:nth-child(11),
  //   td:nth-child(11) {
  //     width: 125px;
  //   }
  //   th:nth-child(12),
  //   td:nth-child(12) {
  //     width: 110px;
  //   }
  //   th:nth-child(13),
  //   td:nth-child(13) {
  //     width: 95px;
  //   }
  th:nth-child(14),
  td:nth-child(14) {
    width: 150px;
  }
  th:nth-child(15),
  td:nth-child(15) {
    width: 140px;
  }
  th:nth-child(16),
  td:nth-child(16) {
    width: 105px;
  }

  @media screen and (max-width: 1600px) {
    td {
      white-space: pre-wrap;
    }
  }
}
.ant-table table {
  border: 0 !important;
  border-radius: 2px;
  thead {
    > tr {
      &:first-child {
        background: linear-gradient(to bottom, rgba(253, 141, 39, 1), rgba(231, 119, 17, 1)) !important;
      }
    }
  }
  tbody {
    > tr {
      background-color: $wht;

      &:nth-child(2n + 1) {
        background-color: $wht;
      }
    }
  }
}

.ant-table-column-title:first-letter {
  text-transform: capitalize !important;
}
.ant-table-filter-column-title:first-letter {
  text-transform: capitalize !important;
}

.ant-table-thead > tr > th {
  background: linear-gradient(to bottom, rgba(253, 141, 39, 1), rgba(231, 119, 17, 1)) !important;
  color: #fff;
  // background: none !important;
  font-size: 12px;
  font-weight: bold;
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, sans-serif;

  &.ant-table-column-has-actions {
    &.ant-table-column-has-sorters:hover {
      background-clip: padding-box;
      background-color: darken($c-primary, 3);
    }

    &.ant-table-column-has-filters {
      &:hover {
        .ant-table-filter-icon:hover,
        .anticon-filter:hover {
          background-color: darken($c-primary, 3);
          color: #fff;
        }
      }

      .anticon-filter {
        &.ant-table-filter-open {
          background-color: darken($c-primary, 3);
          color: #fff;
        }
      }

      .ant-table-filter-icon.ant-table-filter-open {
        background-color: darken($c-primary, 3);
        color: #fff;
      }
    }
  }

  &.ant-table-column-sort {
    background-color: darken($c-primary, 3);
  }

  .ant-table-column-sorter {
    color: #fff;

    &-down.on,
    &-up.on {
      color: yellow;
    }
  }

  .ant-table-filter-selected.anticon-filter {
    color: yellow;
  }
}

.ant-table-bordered {
  .ant-table-thead > tr > th {
    border-right-color: lighten($c-gray, 5);
    border-bottom: 1px solid $c-gray;
    color: $table-title-color;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .ant-table-tbody > tr > td {
    border-right: 0;
    border-right: 1px solid $c-gray;
    border-bottom: 1px solid $c-gray;
  }
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  border-bottom: 0;
  border-right: 1px solid $c-gray;
  padding: 12px;
  font-size: 13px;
}

.ant-table-thead > tr > th {
  border-right-color: lighten($c-gray, 5);
}

.ant-table-bordered .ant-table-thead > tr > th:last-child,
.ant-table-bordered .ant-table-tbody > tr > td:last-child {
  border-right: 0 !important;
}

//.ant-table-tbody>tr:hover:not(.ant-table-expanded-row):nth-child(2n)>td {
//    background-color: #f0f1f4;
//}
//
//.ant-table-tbody>tr:hover:not(.ant-table-expanded-row)>td {
//    background-color: #fcfcfd;
//}

.ant-table-thead > tr > th .ant-table-filter-icon,
.ant-table-thead > tr > th .anticon-filter {
  color: #ffffff;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  font-size: 12px;
  padding: 10px 6px !important;
}
i.controls::before {
  font-size: 12px;
}

@media only screen and (max-width: 1250px) {
  .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters {
    padding-right: 22px !important;
  }

  .ant-table-thead > tr > th {
    font-size: 10px !important;
  }
}

tr.ant-table-expanded-row td > .ant-table-wrapper {
  margin: 0;
}

.table-actions {
  a {
    &:hover {
      opacity: 0.5;
    }
  }
}

.ant-table-filter-dropdown-link {
  &:hover {
    cursor: pointer;
  }
}

.ant-table-column-title {
  color: $table-title-color;
} // color za sve naslove kolona

.ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: 2px;
  color: $wht;
} //color za poslednji naslov

#upload-box-avatar-0 {
  border: none !important;
  .anticon .anticon-edit svg {
    color: $wht;
  }
}

.ant-table {
  // border-radius: $table-border-radius-bottom;
  overflow: hidden;
  border-top: 1px solid #f0f0f0 !important;
  border-right: 1px solid #f0f0f0 !important;
  margin-bottom: 30px;
}

.ant-table-wrapper {
  .ant-spin-container {
    overflow: unset !important;
  }
}

.ant-input-prefix {
  margin-right: 12px;
}

// .ant-table-cell {
//   //padding-left: 1.5% !important;
// }

.ant-table-wrapper {
  & .ant-table-header {
    & table {
      & tr {
        & th {
          // background-color: $primary-color;
          border-top: 1px solid $borderHeaderTable;
          border-bottom: 1px solid $borderHeaderTable;
          border-right: 1px solid $borderHeaderTable !important;

          &:first-child {
            border: 1px solid $borderHeaderTable;
            border-left: 0;
          }

          &:last-child {
            border: 1px solid $borderHeaderTable;
            border-left: 0;
          }
        }
      }
    }
  }

  & .ant-table-body {
    & table {
      & tr {
        & td {
          border-bottom: 1px solid $borderTable;
          border-right: 1px solid $borderTable !important;

          &:first-child {
            border: 1px solid $borderTable;
            border-top: 0;
            border-left: 0;
          }

          &:last-child {
            border: 1px solid $borderTable;
            border-left: 0;
            border-top: 0;
          }
        }
      }
    }
  }
}

.ant-table.ant-table-bordered > .ant-table-container {
  border: 0 !important;
}

// ::-webkit-scrollbar {
//   display: none;
// }

body {
  & ::-webkit-scrollbar {
    display: none;
  }
}
.ant-table-filter-column {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
.ant-table-filter-trigger {
  margin: 0;
  margin-right: 2px !important;
  padding: 0;
}
.ant-table-body {
  max-height: none !important;
}
