html {
  font-size: 100%;
}

body {
  color: $txtColor !important;
  font-family: $font-stack !important;
  @include font-size(16);
  line-height: 1.2;
  text-size-adjust: 100%;
  font-family: 'Montserrat', sans-serif !important;
}

h2 {
  font-size: 16px;
}

hr.big {
  margin: 2rem 0;
  border: 1px solid #dbe2ea;
}

dl {
  dt {
    font-weight: bold;
    margin-right: 0.375em;
    display: inline-block;
    margin-top: 0.625em;

    &:first-child {
      margin-top: 0;
    }
  }

  dd {
    display: inline;
    &:after {
      content: '\A';
      white-space: pre;
    }
  }
}

.special-title {
  padding: 60px;
  color: grey;
  font-size: 30px;
}
.data-title {
  margin-top: 20px;
  margin-bottom: 0;
  font-weight: bold;
  text-align: center;
  color: grey;
  font-size: 30px;
}

.pagination {
  display: flex;
  justify-content: center;
  gap: 12px;
  margin-top: 20px;
  margin-bottom: 20px;
  flex-wrap: wrap;
  button {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px;
    border-radius: 4px;
    border: 1px solid $k15;
    background: $wht;
    color: #767676;
    font-size: 16px;
    font-weight: 500;
    line-height: 100%;
    cursor: pointer;

    &:hover {
      background: $k15;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }

    &.active {
      color: $orange;
    }
  }
}
